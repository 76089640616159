/**
 * Univerzalni popup pro ruzna pouziti.
 */
var GenericPopup = (function () {
    var popup; // kontejner popupu

    return {
        init: function () {
            popup = $("#generic-popup");
            if (popup.length === 0) {
                return;
            }

            popup.dialog({
                modal: true,
                draggable: false,
                autoOpen: false,
                resizable: false,
                closeText: "",
                width: 860
            });
        },
        setContent: function (content) {
            popup.html(content);
            return this;
        },
        addClasses: function (classes) {
            popup.closest('.ui-dialog').addClass(classes);
            return this;
        },
        open: function () {
            popup.dialog("open");
            return this;
        },
        close: function () {
            popup.dialog("close");
            return this;
        }
    }
})();

$(document).ready(function () {
    GenericPopup.init();
});

module.exports = GenericPopup;