const Loader = require("../Loader");

const DeliveryPickupPopup = (function () {
    var container;
    var searchInput;
    var placeContainer;
    var debounceSearch;
    var ajaxInProgress = null;
    var pickupPlaceUpdatePending = null;
    var deliveryPickupType;

    const showMessage = function (status, text) {
        const messageContainer = $(".js-dp-message-container");
        const msg = $("<article>")
            .addClass("message message--" + status).text(text);
        messageContainer.empty().append(msg);
    };

    var getPlaces = function () {
        if (searchInput.val().length < 2) {
            // odesilaji se pouze termy s delkou vetsi nez 1
            return;
        }

        if (ajaxInProgress !== null) {
            ajaxInProgress.abort();
        }

        ajaxInProgress = $.ajax({
            method: "GET",
            url: container.data("search-url"),
            data: {
                q: searchInput.val(),
                service: deliveryPickupType
            },
            beforeSend: function () {
                Loader.show();
            },
            success: function (response) {
                if (response.status === "success") {
                    placeContainer.html(response.html).show();
                }
            },
            error: function () {
                showMessage("error", container.data("error-msg"))
            },
            complete: function () {
                ajaxInProgress = null;
                Loader.hide();
            }
        });
    };

    return {
        init: function () {
            container = $("#delivery-pickup-popup");
            if (!container.length) {
                return;
            }

            container.dialog({
                modal: true,
                draggable: false,
                autoOpen: false,
                resizable: false,
                position: {my: "center top", at: "center bottom", of: ".js-page-header"}
            });

            container.closest(".ui-dialog").addClass('delivery-pickup-popup-wrapper');

            searchInput = container.find(".js-delivery-pickup-search");
            placeContainer = container.find(".js-place-container");

            // po kliknuti na tlacitko Vybrat pobocku otevri popup
            // Zasilkovna ma vlastni widget
            $(".js-order-container").on("click", ".js-delivery-pickup-widget:not([data-type='zasilkovna']) .js-choose-branch", function () {
                deliveryPickupType = $(this).closest(".js-delivery-pickup-widget").data("type");

                // reset vyhledavani
                searchInput.val("");
                placeContainer.html("").hide();
                container.find(".js-save").hide();

                container.dialog("open");
            });

            // Vyhledávat začnu až půl sekundy po zadání posledního znaku
            searchInput.on("input", function () {
                if (debounceSearch !== null) {
                    clearTimeout(debounceSearch);
                    debounceSearch = null;
                }
                debounceSearch = setTimeout(function () {
                    debounceSearch = null;
                    getPlaces();
                }, 500);
            });

            placeContainer.on("click", ".js-place", function () {
                $(this).toggleClass("is-active");

                placeContainer.find(".js-place").not($(this)).removeClass("is-active");

                if ($(this).hasClass("is-active")) {
                    // je vybrano
                    container.data("selected-id", $(this).data("id"))
                        .data("selected-name", $(this).data("fullname"));
                    container.find(".js-save").show();
                } else {
                    // neni nic vybrano
                    container.data("selected-id", "")
                        .data("selected-name", "");
                    container.find(".js-save").hide();
                }
            });

            container.on("click", ".js-save", function () {
                if ($(this).hasClass("is-disabled")) {
                    return;
                }

                $(".js-delivery-pickup-widget[data-type='" + deliveryPickupType + "']")
                    .find(".js-branch-name").text(container.data("selected-name"));

                var data = new FormData();
                data.append("place_id", container.data("selected-id"));
                data.append("place_name", container.data("selected-name"));

                pickupPlaceUpdatePending = $.ajax({
                    url: container.data("update-url"),
                    method: "POST",
                    data: data,
                    cache: false,
                    contentType: false,
                    processData: false,
                    complete: function () {
                        pickupPlaceUpdatePending = null;
                    }
                });

                container.dialog("close");
            });
        }
    }
})();

$(document).ready(function () {
    DeliveryPickupPopup.init();
});