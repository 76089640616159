var BasketUpdater = require("../order/view/BasketUpdater");
var GenericPopup = require("../GenericPopup");
var FlashMessage = require("../FlashMessage");
const GtmEvents = require("../GtmEvents");
const Carousels = require("../Carousels");

var ProductAjaxAddToBasket = (function () {
    return {
        init: function () {
            var body = $("body");
            if (!body.data("ajax-add-to-basket")) {
                return;
            }

            body.on("click", ".js-add-to-basket-submit", function (e) {
                e.preventDefault();
                e.stopImmediatePropagation();

                var form = $(this).closest("form");

                var quantity = form.find(":input[name*=quantity]").val();
                var slug = form.find(":input[name*=slug]").val();
                var variant_id = form.find(":input[name*=variant_id]").val();

                var data = new FormData();
                data.append("quantity", parseInt(quantity));
                data.append("slug", slug);
                data.append("operation", "add");
                if (body.data("basket-upsell")) {
                    data.append("show_upsell_popup", "1");
                }
                if (variant_id) {
                    data.append("variant_id", variant_id);
                }

                // gtm pridani zbozi do kosiku
                GtmEvents.sendBasketUpdateEvent("addToCart", parseInt(quantity), $(this));

                var successMessage = $(this).data("success-message");
                $.ajax($(this).data("ajax-url"), {
                    method: "POST",
                    data: data,
                    cache: false,
                    contentType: false,
                    processData: false,
                    success: function (response) {
                        if (response.status === "success") {
                            BasketUpdater.updatePreview(response.data.basket.totalPrice);

                            if (response.upsell) {
                                GenericPopup
                                    .setContent(response.upsell).open().addClasses('ui-dialog-upsell-popup-products');
                                // inicializace slideshow produktu pri kazdem nacteni upsellu
                                Carousels.initUpsellPopupCarousel();
                            } else {
                                new FlashMessage({
                                    "status": "message--success",
                                    "text": successMessage
                                })
                            }
                        }
                    },
                });
            });
        }
    }
})();

$(document).ready(function () {
    ProductAjaxAddToBasket.init();
});