var Utils = require("./Utils");

/**
 * Jednoducha aplikacni logika, pro kterou je zbytecne
 * vytvaret vlastni objekt.
 *
 * @type {{init}}
 */
var GeneralLogic = (function () {

    /**
     * Univerzalni scroll s animaci po kliknuti na element.
     *
     * Jako trigger slouzi element s tridou js-scroll-trigger.
     * Jako cil se urci element nalezeny podle jQuery selectoru v data
     * atributu "target". Pokud je nalezeno vice elementu, pouzije se ten
     * prvni.
     *
     * Volitelne lze nastavit rychlost scrollu pomoci data atributu "duration".
     * Vychozi rychlost je 500, tzn. 0,5 sekundy.
     */
    function initScrollToElementLogic() {
        $(".js-scroll-trigger").click(function () {
            var target = $($(this).data("target"));
            if (target.length === 0) {
                return;
            }

            if (target.length > 1) {
                target = target.first();
            }

            var duration = parseInt($(this).data("duration"));
            if (isNaN(duration)) {
                duration = 500;
            }

            Utils.scrollBody(target.offset().top, duration);
        });
    }

    /**
     * Univerzalni zabaleni/rozbaleni elementu.
     *
     * Jako trigger slouzi element s tridou js-collapse-trigger.
     * Jako cil se urci element nalezeny podle jQuery selectoru v data
     * atributu "target". Pokud je nalezeno vice elementu, pouzije se ten
     * prvni.
     *
     * Volitelne lze nastavit rychlost rozbaleni/zabaleni pomoci data atributu "duration".
     * Vychozi rychlost je 500, tzn. 0,5 sekundy.
     */
    function initCollapsibleSectionLogic() {
        $(".js-collapse-trigger").click(function () {
            var target = $($(this).data("target"));
            if (target.length === 0) {
                return;
            }

            if (target.length > 1) {
                target = target.first();
            }

            var duration = parseInt($(this).data("duration"));
            if (isNaN(duration)) {
                duration = 500;
            }

            if (target.is(":visible")) {
                target.removeClass("is-active");
                target.slideUp(duration);
            } else {
                target.addClass("is-active");
                target.slideDown(duration);
            }
        });
    }

    /**
     * Logika skryti info listy
     */
    function initInfoBarLogic() {
        let infoBar = $(".js-info-bar");

        // skryti info listy
        $(".js-info-bar-close").click(function () {
            let name = infoBar.data("hide-cookie-name");
            let value = "true";

            let expires = "";
            if (infoBar.data("hide-interval")) {
                let date = new Date();
                let interval = parseInt(infoBar.data("hide-interval")) || 1; // x dni
                date.setTime(date.getTime() + (interval*24*60*60*1000));
                expires = "; expires=" + date.toUTCString();
            }

            document.cookie = name + "=" + (value || "")  + expires + "; path=/";

            infoBar.fadeOut(100);
        });
    }

    return {
        init: function () {
            initScrollToElementLogic();
            initCollapsibleSectionLogic();
            initInfoBarLogic();
        }
    }
})();

$(document).ready(function () {
    GeneralLogic.init();
});