var AddressHelper = require("../../address/AddressHelper");

var OrderValidator = (function() {
    var clearInputErrors = function (input) {
        input.removeClass("is-invalid");
        input.closest(".js-form-item").find(".js-form-errors").empty();
    };

    return {
        startInputProgress: function (input) {
            input.addClass("is-processing is-validating");
        },

        endInputProgress: function (input) {
            input.removeClass("is-processing is-validating");
        },

        startFormProgress: function () {
            $(".js-order-submit").addClass("is-validating");
        },

        endFormProgress: function () {
            $(".js-order-submit").removeClass("is-validating");
        },

        handleInputSuccess: function (input) {
            clearInputErrors(input);

            if (input.val() !== "") {
                input.addClass("is-valid");
            } else {
                input.removeClass("is-valid");
            }

            // odstraneni erroru pro cislo orientacni a popisne, ktere s tim muze souviset
            var complementary = AddressHelper.getStreetInfoComplementaryNumber(input);
            if (typeof complementary !== "undefined") {
                clearInputErrors(complementary);
            }
        },

        handleInputError: function (input, errors) {
            clearInputErrors(input);
            this.showAllErrors(errors);
        },

        showAllErrors: function (errors) {
            $.each(errors, function () {
                var target = $("[name='" + this.name + "']").not(":hidden");
                clearInputErrors(target);
                target.removeClass("is-valid").addClass("is-invalid");

                var message = $("<div>")
                    .addClass("form-item__error")
                    .text(this.message);

                target.closest(".js-form-item").find(".js-form-errors").append(message);
            });
        }
    };
})();

module.exports = OrderValidator;