// CSS
require("slick-carousel/slick/slick.css");
require("jquery-ui/themes/base/base.css");
require("jquery-ui/themes/base/datepicker.css");
require("../css/ext/fresco/fresco.css");
require("../css/index.css");
require("../css/admin-switch.css");

// JS
require("./component/QuantityBox");
require("./component/LoginPopup");
require("./component/SearchBar");
require("./component/order/Order");
require("./component/order/OrderSuccess");
require("./component/order/OrderDetail");
require("./component/form/Forms");
require("./component/product/ParameterSelection");
require("./component/product/ProductList");
require("./component/product/ProductAjaxAddToBasket");
require("./component/product/UpsellPopup");
require("./component/news/NewsList");
require("./component/event/EventList");
require("./component/GeneralLogic");
require("./component/GroupMobile");
require("./component/user/OrderList");
require("./component/GtmEvents");
require("./component/form/NewsletterForm");
require("slick-carousel");
require("./component/Carousels");
global.CookiesConfirmDialog = require("./component/CookiesConfirmDialog");
require("jquery-ui/ui/widgets/datepicker");
require("jquery-ui/ui/widgets/dialog");
require("./component/ext/fresco");

$(document).ready(function () {
    // obalení tabulky do speciálního divu ošetřujícího široké tabulky (kritické pro tabulky z WYSIWYGu)
    $(".js-process-wide-tables").find("table").wrap("<div class='table-wrapper'></div>");

    // polyfill pro isInteger pro IE
    Number.isInteger = Number.isInteger || function (value) {
        return typeof value === 'number' &&
            isFinite(value) &&
            Math.floor(value) === value;
    };

    // zabrani nekolikanasobnemu odeslani formulare
    $("body").on("click", "button[data-prevent-resubmission=\"true\"]", function (e) {
        e.preventDefault();

        var button = $(this);

        // aby se stihl odeslat formular
        setTimeout(function () {
            button.prop("disabled", true);
        }, 50);

        // odstranit disabled, Firefox si pamatuje stav inputu a pri stisknuti tlacitka Zpet
        // v prohlizeci by ho pri nacteni obsahu nechal disabled
        // 1 vterina pro zabraneni nekolikanasobnemu odeslani staci
        setTimeout(function () {
            button.prop("disabled", false);
        }, 1000);

        $(this).closest("form").submit();
    });
});