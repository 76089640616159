var OrderUpdater = require("./view/OrderUpdater");
var Delivery = require("./Delivery");
var Loader = require("../Loader");
const GtmEvents = require("../GtmEvents");

var Basket = (function () {
    var operations;
    var container;
    var url;

    /**
     * Pridani jedne polozky produktu do kosiku.
     * @param productElem
     */
    var add = function (productElem) {
        changeQuantity(productElem, 1, operations.add);
    };

    /**
     * Odebrani jedne polozky produktu z kosiku.
     * @param productElem
     */
    var remove = function (productElem) {
        changeQuantity(productElem, 1, operations.sub);
    };

    /**
     * Odebrani vsech polozek produktu z kosiku.
     * @param productElem
     */
    var removeAll = function (productElem) {
        changeQuantity(productElem, 0, operations.set);
    };

    /**
     * Nastaveni poctu polozek v kosiku.
     * @param productElem
     * @param quantity
     * @param operation
     */
    var changeQuantity = function (productElem, quantity, operation) {
        var data = new FormData();

        data.append("slug", productElem.data("slug"));
        data.append("quantity", quantity);
        data.append("operation", operation);
        if (productElem.data("variant-id")) {
            data.append("variant_id", productElem.data("variant-id"));
        }

        $.ajax(url, {
            method: "POST",
            data: data,
            cache: false,
            contentType: false,
            processData: false,
            beforeSend: function() {
                Loader.show();
            },
            success: function (response) {
                if (response.status === "success") {
                    if (response.data.basket.products.length === 0) {
                        var orderContainer = container.closest(".js-order-container");
                        orderContainer.find(".js-order-info").hide();
                        orderContainer.find(".js-order-empty-basket").show();
                    }

                    Delivery.updatePaymentMethods(response.paymentMethods);
                    Delivery.updateShippingMethods(response.shippingMethods);
                    OrderUpdater.update(response.data);
                }
            },
            complete: function () {
                Loader.hide();
            }
        });
    };

    return {
        init: function() {
            container = $(".js-basket");
            url = container.data("url");
            operations = {
                add: container.data("operation-add"),
                sub: container.data("operation-sub"),
                set: container.data("operation-set")
            };

            // odebrani vsech produktu jednoho druhu pomoci "x"
            container.find(".js-remove-all").on("click", function () {
                removeAll($(this).closest(".js-basket-item"));
            });

            // nastaveni poctu produktu pomoci inputu
            container.find(".js-quantity").on("change", function () {
                var quantity = parseInt($(this).val());
                var productElem = $(this).closest(".js-basket-item");

                let quantityDiff = quantity - parseInt($(this).data("previous-quantity"));
                if (quantityDiff > 0) {
                    // pridani do kosiku
                    GtmEvents.sendBasketUpdateEvent("addToCart", quantityDiff, productElem);
                } else {
                    // odebrani z kosiku
                    GtmEvents.sendBasketUpdateEvent("removeFromCart", Math.abs(quantityDiff), productElem);
                }
                $(this).data("previous-quantity", quantity);

                changeQuantity(productElem, quantity, operations.set);
            });
        }
    };
})();

$(document).ready(function () {
    Basket.init();
});