const Loader = require("../Loader");
const OrderUpdater = require("./view/OrderUpdater");

const FreeGifts = (function () {
    let container;
    let orderContainer;

    return {
        init: function () {
            container = $(".js-free-gift-container");
            if (!container.length) {
                return;
            }

            orderContainer = $(".js-order-container");

            container.on("click", ".js-free-gifts-toggle", function () {
                container.find(".js-free-gift-list").slideToggle(150, "linear");
            });

            // vyber darku zdarma
            container.on("click", ".js-choose-free-gift", function (e) {
                e.preventDefault();

                const item = $(this).closest(".js-free-gift-item");
                const giftId = item.data("id");

                $.ajax({
                    url: container.data("url"),
                    method: "POST",
                    data: {id: giftId},
                    beforeSend: function () {
                        Loader.show();
                    },
                    success: function (response) {
                        if (response.status === "success") {
                            OrderUpdater.updateFreeGifts(response.data.freeGift);
                        } else {
                            orderContainer.find(".js-dynamic-messages").append($("<article>")
                                .addClass("message message--error").text(response.message));
                        }
                    },
                    complete: function () {
                        Loader.hide();
                    }
                });
            });

            // odstaneni darku zdarma
            $("body").on("click", ".js-remove-free-gift", function () {
                $.ajax({
                    url: $(this).data("url"),
                    method: "POST",
                    beforeSend: function () {
                        Loader.show();
                    },
                    success: function (response) {
                        if (response.status === "success") {
                            OrderUpdater.updateFreeGifts(response.data.freeGift);
                        } else {
                            orderContainer.find(".js-dynamic-messages").append($("<article>")
                                .addClass("message message--error").text(response.message));
                        }
                    },
                    complete: function () {
                        Loader.hide();
                    }
                });
            });
        }
    }
})();

$(document).ready(function () {
    FreeGifts.init();
});