/**
 * Logika sekce zmeny platebni metody jiz odeslane objednavky
 */
const ChangePaymentMethod = (function () {
    let container;

    return {
        init: function () {
            container = $(".js-change-payment-method-container");
            if (!container.length) {
                return;
            }

            const changePaymentMethodForm = container.find(".js-form");
            const changePaymentMethodToggle = container.find(".js-toggle");
            const changePaymentFormSubmit = changePaymentMethodForm.find(".js-submit");

            changePaymentMethodToggle.on("click", function () {
                // rozbal sekci
                changePaymentMethodForm.slideToggle(150, "linear");
            });

            container.on("click", ".js-cancel", function () {
                // zabal sekci
                changePaymentMethodForm.slideUp(150, "linear");
            });

            changePaymentFormSubmit.on("click", function (e) {
                const currentPaymentMethodId = parseInt(changePaymentMethodForm.data("current-payment-id"));
                if (parseInt(changePaymentMethodForm.find(":input[name='payment_method']").val()) === currentPaymentMethodId) {
                    // nebyla zmenena platebni metoda, pouze zabal sekci
                    e.preventDefault();
                    changePaymentMethodForm.slideUp(150, "linear");
                }
            });
        }
    };
})();

$(document).ready(function () {
    ChangePaymentMethod.init();
});