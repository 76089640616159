const Forms = (function () {
    let initMaterialDesign = function () {
        let setFilledClass = function (input) {
            let formItem = input.closest(".js-form-item");
            if (!formItem.length) {
                return;
            }

            if (input.val()) {
                formItem.removeClass("is-empty").addClass("is-filled");
            } else {
                formItem.addClass("is-empty").removeClass("is-filled");
            }
        };

        // na tyto inputy se aplikuje material design
        let selector = "input[type='text'], input[type='password'], " +
        "input[type='tel'], input[type='email'], " +
        "input[type='url'], input[type='number'], textarea, select";

        $(selector).each(function () {
            setFilledClass($(this));
        });

        $(document).on("input material.change", selector, function (event) {
            setFilledClass($(event.target));
        });
    };

    return {
        init: function () {
            $(".js-datepicker").datepicker({
                dateFormat: "d. m. yy"
            });

            // zmena meny
            $(".js-currency").not(".is-active").click(function () {
                let form = $("#change-currency");
                form.find(":input.js-currency-input").val($(this).data("currency"));
                form.submit();
            });

            initMaterialDesign();
        }
    };
})();

$(document).ready(function () {
    Forms.init();
});