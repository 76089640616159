var Utils = require("../Utils");
var ProductUpdater = require("./ProductUpdater");

/**
 * Business logika vyberu hodnot variantnich parametru  v detailu produktu.
 *
 * @type {{init}}
 */
var ParameterSelection = (function () {
    var productContainer = $(".js-product-basic-info");

    /**
     * Vlozi pocatecni state vyberu hodnot parametru do browser history.
     */
    function initBrowserHistoryState() {
        var href = window.location.pathname + window.location.search;
        var state = {
            paramQuery: getParameterQuery()
        };

        history.replaceState(state, "", href);
    }

    /**
     * Vlozi novy state vyberu hodnot parametru do browser history.
     *
     */
    function pushNewBrowserHistoryState(options) {
        var href = Utils.createUrl(window.location.pathname, options.query);
        var state = {
            paramQuery: options.paramQuery
        };

        history.pushState(state, "", href);
    }

    /**
     * Varti query string, ktery reprezentuje vybrane hodnoty parametru.
     *
     * @returns {string}
     */
    function getParameterQuery() {
        var paramValues = [];
        $.each($(".js-param-value"), function () {
            // dve moznosti select/radio
            if ($(this).prop("type") === "radio") {
                if ($(this).is(":checked")) {
                    paramValues.push($(this).val());
                }
            } else {
                if ($(this).val() !== "") {
                    paramValues.push($(this).val());
                }
            }
        });

        var query = "";

        $.each(paramValues, function (idx, elem) {
            if (idx > 0) {
                query += "&";
            }
            query += "p[]=" + elem;
        });

        return query;
    }

    /**
     * Nacte konfiguraci vyberu hodnot variantnich parametru dle query.
     *
     * @param query
     * @param newHistoryState
     */
    function loadParameterSelection(query, newHistoryState) {
        if (typeof newHistoryState === "undefined") {
            newHistoryState = true;
        }

        var selectionContainer = $(".js-param-selection");
        var url = selectionContainer.data("select-url") + "?";

        url += query;
        url += "&slug=" + selectionContainer.data("slug");

        $.get(url, function (response) {
            if (response.status === "success") {
                ProductUpdater.update(response);

                if (newHistoryState) {
                    pushNewBrowserHistoryState({
                        "query": response.query,
                        "paramQuery": query
                    });
                }
            }
        });
    }

    return {
        init: function () {
            if (productContainer.length === 0) {
                return;
            }

            // pocatecni stav do historie prohlizece
            initBrowserHistoryState();

            // vyber parametru v detailu produktu
            productContainer.on("change", ".js-param-value", function () {
                loadParameterSelection(getParameterQuery());
            });

            //stisknuto tlacitko zpet v prohlizeci
            window.onpopstate = function (e) {
                if (e.state != null && e.state.hasOwnProperty("paramQuery")) {
                    // nacti konfiguraci vyberu parametru
                    loadParameterSelection(e.state["paramQuery"], false);
                }
            };

            // scroll na vyber varianty
            $(".js-choose-variant-button").click(function () {
                Utils.scrollBody($(".js-param-selection").offset().top - 50, 500);
            });
        }
    };
})();

$(document).ready(function () {
    ParameterSelection.init();
});