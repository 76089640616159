require("./ChangePaymentMethod");

const OrderDetail = (function () {
    let container;

    return {
        init: function () {
            container = $(".js-order-detail");
            if (!container.length) {
                return;
            }
        }
    };
})();

$(document).ready(function () {
    OrderDetail.init();
});