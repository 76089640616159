var OrderUpdater = require("./view/OrderUpdater");
var Loader = require("../Loader");

var Delivery = (function () {
    var orderContainer;
    var shippingMethodsContainer;
    var paymentMethodsContainer;
    var pickupPlaceUpdatePending = null;

    /**
     * Vytvari checkboxy pro zpusoby dopravy a platby.
     * @param container
     * @param data
     */
    var updateDeliveryServices = function (container, data) {
        container.empty();

        $.each(data, function () {
            var prototype = container.data("prototype");

            prototype = prototype.replace(/__id__/g, this.id)
                .replace(/__name__/g, this.name)
                .replace(/__description__/g, this.description)
                .replace(/__price__/g, this.price);

            if (typeof this.system_name !== "undefined") {
                prototype = prototype.replace(/__sysname__/g, this.system_name);
            }

            // vytvor jquery objekt
            var deliveryService = $(prototype);

            const deliveryPickupWidget = deliveryService.find(".js-delivery-pickup-widget");

            if (typeof this.system_name === "undefined") {
                // pro polozky, co nepotrebuji pobocky, odstran widget
                deliveryPickupWidget.remove();
            } else if (this.system_name) {
                // nastav aktualni vybranou pobocku
                deliveryPickupWidget.find(".js-branch-name").text(this.attributes.place_name);
            }

            if (typeof this.price === "undefined") {
                deliveryService.find(".js-price").remove();
            }

            if (!this.available) {
                deliveryService.addClass("is-disabled");
                deliveryService.find(".js-disabled-text").show();
            }

            container.append(deliveryService);
        });
    };

    /**
     * Vytvari checkboxy pro zpusoby dopravy.
     * @param shippingMethodsData
     */
    var updateShippingMethods = function (shippingMethodsData) {
        updateDeliveryServices(shippingMethodsContainer, shippingMethodsData);
    };

    /**
     * Vytvari checkboxy pro zpusoby platby.
     * @param paymentMethodsData
     */
    var updatePaymentMethods = function (paymentMethodsData) {
        updateDeliveryServices(paymentMethodsContainer, paymentMethodsData);
    };

    /**
     * Provadi zmenu dodaci zeme.
     * @param countryId
     */
    var changeDeliveryCountry = function (countryId) {
        var data = new FormData();
        data.append("countryId", countryId);

        $.ajax({
            url: $(".js-delivery").data("country-url"),
            method: "POST",
            data: data,
            cache: false,
            contentType: false,
            processData: false,
            beforeSend: function() {
                Loader.show();
            },
            success: function (response) {
                if (response.status === "success") {
                    updateShippingMethods(response.data.shippingMethods);
                    updatePaymentMethods(response.data.paymentMethods);

                    OrderUpdater.update(response.data.order);
                }
            },
            complete: function () {
                Loader.hide();
            }
        });
    };

    /**
     * Provadi zmenu zpusobu dopravy.
     * @param formData
     */
    var changeShippingMethod = function (formData) {
        $.ajax({
            url: $(".js-delivery").data("shipping-url"),
            method: "POST",
            data: formData,
            cache: false,
            contentType: false,
            processData: false,
            beforeSend: function() {
                Loader.show();
            },
            success: function (response) {
                if (response.status === "success") {
                    updatePaymentMethods(response.data.paymentMethods);

                    OrderUpdater.update(response.data.order);
                }
            },
            complete: function () {
                Loader.hide();
            }
        });
    };

    /**
     * Provadi zmenu zpusobu platby.
     * @param formData
     */
    var changePaymentMethod = function (formData) {
        $.ajax({
            url: $(".js-delivery").data("payment-url"),
            method: "POST",
            data: formData,
            cache: false,
            contentType: false,
            processData: false,
            beforeSend: function() {
                Loader.show();
            },
            success: function (response) {
                if (response.status === "success") {
                    updateShippingMethods(response.data.shippingMethods);

                    OrderUpdater.update(response.data.order);
                }
            },
            complete: function () {
                Loader.hide();
            }
        });
    };

    /**
     * Callback pri zavreni widgetu zasilkovny.
     * @param place
     */
    const onPacketaWidgetClose = function (place) {
        if (place) {
            if (pickupPlaceUpdatePending) {
                pickupPlaceUpdatePending.abort();
            }

            $(".js-delivery-pickup-widget[data-type='zasilkovna']")
                .find(".js-branch-name").text(place.name);

            var data = new FormData();
            data.append("place_id", place.id);
            data.append("place_name", place.name);

            pickupPlaceUpdatePending = $.ajax({
                url: orderContainer.data("pickup-place-update-url"),
                method: "POST",
                data: data,
                cache: false,
                contentType: false,
                processData: false,
                complete: function () {
                    pickupPlaceUpdatePending = null;
                }
            });
        }
    };

    /**
     * Otevre widget zasilkovny
     */
    const openPacketaWidget = function () {
        var options = {
            webUrl: window.location.origin,
            country: ($(".js-country").find("option:selected").data("country-code") || "cz").toLowerCase(),
            language: (orderContainer.data("locale") || "cs").toLowerCase(),
            primaryButtonColor: "#000000",
            fontColor: "#000000",
            fontFamily: "Arial",
            backgroundColor: "#FFFFFF"
        };

        if (typeof Packeta !== "undefined") {
            Packeta.Widget.pick(orderContainer.data("zasilkovna-api-key"), onPacketaWidgetClose, options)
        }
    };

    const resetDeliveryPickupWidgets = function () {
        const widgets = $(".js-service-item").find(".js-delivery-pickup-widget");
        // skryt vyber pobocky u vsech zpusobu dopravy
        widgets.hide();
        // reset vybraneho vydejniho mista
        widgets.find(".js-branch-name").text("");
    };

    return {
        init: function () {
            orderContainer = $(".js-order-container");
            shippingMethodsContainer = $(".js-shipping-methods");
            paymentMethodsContainer = $(".js-payment-methods");

            // zmena dodaci zeme
            orderContainer.on("change", ".js-country", function () {
                changeDeliveryCountry($(this).val());
            });

            // handler otevreni widgetu zasilkovny pro vyber pobocky
            orderContainer.on("click", ".js-delivery-pickup-widget[data-type='zasilkovna'] .js-choose-branch", openPacketaWidget);

            // zmena zpusobu dopravy
            orderContainer.on("change", ".js-shipping", function () {
                var otherShippingMethods = orderContainer.find(".js-shipping").not($(this));
                // odskrtnout ostatni vybrane metody
                otherShippingMethods.prop("checked", false);

                // skryje vsechny popisky zpusobu dopravy
                shippingMethodsContainer.find(".js-description").hide();

                resetDeliveryPickupWidgets();

                var data = new FormData();

                if ($(this).is(":checked")) {
                    // zobrazi popisek pro zvolenou variantu
                    $(this).closest(".js-service-item").find(".js-description").show();

                    data.append("shippingId", $(this).val());
                } else {
                    data.append("shippingId", "-1");
                }

                changeShippingMethod(data);
            });

            // zmena zpusobu platby
            orderContainer.on("change", ".js-payment", function () {
                var otherPaymentMethods = orderContainer.find(".js-payment").not($(this));
                // odskrtnout ostatni vybrane metody
                otherPaymentMethods.prop("checked", false);

                // skryje vsechny popisky zpusobu platby
                paymentMethodsContainer.find(".js-description").hide();

                var data = new FormData();

                if ($(this).is(":checked")) {
                    // zobrazi popisek pro zvolenou variantu
                    $(this).closest(".js-service-item").find(".js-description").show();

                    data.append("paymentId", $(this).val());
                } else {
                    data.append("paymentId", "-1");
                }

                changePaymentMethod(data);
            });
        },
        updateShippingMethods: updateShippingMethods,
        updatePaymentMethods: updatePaymentMethods
    };
})();

$(document).ready(function () {
    Delivery.init();
});

module.exports = Delivery;