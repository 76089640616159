/**
 * Stara se o aktualizaci udaju na strance pri vyberu hodnot variantnich parameteru v detailu produktu
 * @type {{}}
 */
var ProductUpdater = (function () {
    /**
     * Aktualizuje zakladni informace o produktu.
     *
     * @param productHtml
     */
    function updateInfo(productHtml) {
        $(".js-product-basic-info").html(productHtml);
    }

    /**
     * Aktualizuje nadpis stranky.
     *
     * @param productName
     */
    function updatePageTitle(productName) {
        var parts = document.title.split("|");
        if (parts.length > 1) {
            document.title = productName + " |" + parts[1];
        }
    }

    function updateProductName(productName) {
        $(".js-product-name").text(productName);
    }

    /**
     * Aktualizuje src vsech obrazku produktu.
     *
     * @param images
     */
    function updateProductImages(images) {
        if (images.length === 0) {
            return;
        }

        $(".js-product-images").html(images);
    }

    /**
     * Aktualizuje odkazy pro sdileni na soc. sitich.
     *
     * @param shareLinksHtml
     */
    function updateShareLinks(shareLinksHtml) {
        $(".js-product-sharings").html(shareLinksHtml);
    }

    /**
     * Aktualizuje posledni uroven drob. navigace.
     *
     * @param productName
     */
    function updateBreadcrumbs(productName) {
        $(".js-breadcrumbs-last-item").text(productName);
    }

    /**
     * Zobrazi/skryje formular pro nakup produktu.
     *
     * @param response
     */
    function toggleAddToBasketForm(response) {
        var chooseVariantButton = $(".js-choose-variant-button");
        var addToBasketForm = $(".js-add-to-basket-form");
        var variantIdInput = addToBasketForm.find(":input[name*=variant_id]");
        var submitButton = addToBasketForm.find(".js-add-to-basket-submit");
        var variantId = response.variant;

        if (variantId) {
            // je vybrana varianta - lze pridat do kosiku
            chooseVariantButton.hide();
            addToBasketForm.show();
            variantIdInput.val(variantId);
            submitButton.data("variant-id", variantId);
        } else {
            // neni vybrana varianta - nelze pridat do kosiku
            chooseVariantButton.show();
            addToBasketForm.hide();
            variantIdInput.val("");
            submitButton.data("variant-id", "");
        }

        // update metadat tlacitka
        submitButton.data("name", response.name)
            .data("price", response.price)
            .data("id", response.id);
    }

    /**
     * Zobrazi skryje tag produktu s procentualni slevou.
     *
     * @param response
     */
    function toggleSaleTag(response) {
        var saleTag = $(".js-product-tag-sale");
        if (response.variant && response.sale) {
            saleTag.show(response.sale).find(".js-product-tag-sale-amount").text(response.sale);
        } else {
            saleTag.hide();
        }
    }

    /**
     * Pridani dat pro GTM.
     *
     * @param html
     */
    function addGtmInfo(html) {
        $(".js-product-basic-info").after(html);
    }

    return {
        /**
         * Aktualizuje stranku detailu produktu po zmene vyberu hodnot variantnich parametru.
         *
         * @param response
         */
        update: function (response) {
            updateInfo(response.html.product);
            updatePageTitle(response.name);
            updateProductName(response.name);
            updateProductImages(response.images);
            updateShareLinks(response.html.share);
            updateBreadcrumbs(response.name);
            toggleAddToBasketForm(response);
            toggleSaleTag(response);

            if (response.html.gtm_info) {
                addGtmInfo(response.html.gtm_info);
            }
        }
    };
})();

module.exports = ProductUpdater;