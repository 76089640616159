var GenericPopup = require("../GenericPopup");
var BasketUpdater = require("../order/view/BasketUpdater");
var DeliveryUpdater = require("../order/view/DeliveryUpdater");
var OrderUpdater = require("../order/view/OrderUpdater");
var numeral = require("numeral");
require("../../../../node_modules/numeral/min/locales.min");

/**
 * Popup pri vlozeni produktu do kosiku.
 *
 * @type {{init: UpsellPopup.init}}
 */
var UpsellPopup = (function () {
    return {
        init: function () {
            var body = $("body");
            if (!body.data("basket-upsell")) {
                return;
            }

            // nastaveni jazyka pro formatovac cisel
            numeral.locale($("html").prop("lang") || "cs");

            // zavreni popupu
            body.on("click", ".js-upsell-popup .js-close", function () {
                 GenericPopup.close();
            });

            // uprava mnozsvti zbozi v kosiku prideneho pomoci upsellu
            // use case: Pridam 4 kusy, ale po zobrazeni popupu si to rozmyslim
            // a chci jich vlastne 5.
            body.on("click", ".js-upsell-popup .js-update-quantity", function () {
                var container = $(this).closest(".js-container");
                var pricePerItem = $(this).data("price-per-item");
                var inputQuantity = container.find(".js-quantity").val();
                var slug = container.find(":input[name*=slug]").val();
                var variant_id = container.find(":input[name*=variant_id]").val();

                // predchozi pocet toho sameho produktu v kosiku + obsah inputu
                // upravenujeme celkove mnozstvi v kosiku
                var quantity = parseInt($(this).data("previous-quantity")) + parseInt(inputQuantity);
                var data = new FormData();
                data.append("quantity", quantity);
                data.append("slug", slug);
                data.append("operation", "set");
                if (variant_id) {
                    data.append("variant_id", variant_id);
                }

                var successMessage = $(this).data("success-msg");
                $.ajax($(this).data("ajax-url"), {
                    method: "POST",
                    data: data,
                    cache: false,
                    contentType: false,
                    processData: false,
                    success: function (response) {
                        if (response.status === "success") {
                            // update nahledu kosiku v hlavicce
                            BasketUpdater.updatePreview(response.data.basket.totalPrice);

                            // update prepravy (napr. doprava zdarma)
                            DeliveryUpdater.update(response.data.delivery);

                            OrderUpdater.updateFreeGifts(response.data.freeGift);

                            // uprava celkove ceny produktu
                            var totalAddedProductPrice = inputQuantity * parseInt(pricePerItem);
                            $(".js-upsell-price").text(numeral(totalAddedProductPrice).format("0,0"));

                            // update flash message
                            $(".js-upsell-msg").remove();
                            var message = $("<article>")
                                .addClass("message message--success js-upsell-msg")
                                .text(successMessage);
                            container.prepend(message);
                        }
                    },
                });
            });
        }
    }
})();

$(document).ready(function () {
    UpsellPopup.init();
});