const Loader = require("../Loader");

const NewsletterForm = (function () {
    let container;

    return {
        init: function () {
            container = $(".js-newsletter-form-container");
            if (!container.length) {
                return;
            }

            container.on("submit", ".js-newsletter-form", function (e) {
                e.preventDefault();

                $.ajax({
                    url: $(this).prop("action"),
                    method: $(this).prop("method"),
                    data: $(this).serialize(),
                    beforeSend: function () {
                        Loader.show();
                    },
                    success: function (response) {
                        container.html(response.html);
                    },
                    complete: function () {
                        Loader.hide();
                    }
                });
            });
        }
    }
})();

$(document).ready(function () {
    NewsletterForm.init();
});