/**
 * Cookies confirm window
 */
var CookiesConfirmDialog = (function () {
    var cookieSettingsName = 'cookieSettings'; // nazev cookie pro evidenci souhlasu
    var cookieCodeName = 'cookieCode'; // nazev cookie s hashem seznamu cookies ve vsech kategoriich
    var cookieIdentificationName = 'cookieSettingsId'; // identifikator souhlasu s nastavenim cookie pro dohledani daneho souhlasu v db

    /**
     * Ulozi cookie s povolenymi pravy
     *
     * @param cookieSettings Nastaveni cookies
     * @param cookieCode Zahashovany seznam vsech cookies behem udeleni souhlasu
     * @param cookieIdentification Nahodne vygenerovany identifikator pro dohledani daneho souhlasu
     * @param exdays Pocet dni do expirace cookie
     */
    var saveCookieSetting = function (cookieSettings, cookieCode, cookieIdentification, exdays = 0) {
        if (!exdays) {
            exdays = parseInt($('.js-confirm-buttons').data('cookievalidity'));
        }

        // ulozeni cookie s aktualnim nastavenim
        setCookie(cookieSettingsName, cookieSettings, exdays);

        // ulozeni cookie se vsemi kategoriemi behem souhlasu
        setCookie(cookieCodeName, cookieCode, exdays);

        // ulozeni cookie s identifikatorome pro dohledani v db
        setCookie(cookieIdentificationName, cookieIdentification, exdays);
    };

    /**
     * Vrati aktualni nastaveni cookies, pokud je aktualni k danemu seznamu
     * @param cookieCode
     * @returns {string|null}
     */
    var getCookieSettings = function (cookieCode) {
        if (cookieCode === getCookie(cookieCodeName)) {
            return getCookie(cookieSettingsName);
        }

        return null;
    };

    /**
     * Nastavi cookie na urcity pocet dni
     * @param cname
     * @param cvalue
     * @param exdays
     */
    var setCookie = function (cname, cvalue, exdays) {
        var d = new Date();
        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
        var expires = "expires=" + d.toUTCString();
        var domain = "." + window.location.hostname;
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/;domain=" + domain;
    };

    /**
     * Vrati value pro cookie se specifickym jmenem
     * @param cname
     * @returns {string}
     */
    var getCookie = function (cname) {
        var name = cname + "=";
        var decodedCookie = decodeURIComponent(document.cookie);
        var ca = decodedCookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    };

    /**
     * Preda upraveny consent do datalayer pomoci gtag.
     * @param allowCategories
     * @param denyCategories
     */
    var sendGtmCookieUpdate = function (allowCategories, denyCategories) {
        var updateCategories = {};

        var i;
        for (i = 0; i < allowCategories.length; i++) {
            updateCategories[allowCategories[i]] = 'granted'
        }

        for (i = 0; i < denyCategories.length; i++) {
            updateCategories[denyCategories[i]] = 'denied'
        }

        if (typeof gtag !== "undefined") {
            gtag('consent', 'update', updateCategories);
        }
    };

    /**
     * Odesle nastaveni o potvrzeni cookies.
     * @param allowCategories
     */
    var sendCookieConfirmData = function (allowCategories, identification) {
        $.ajax({
            url: $('.js-cookies-confirm').data('confirmurl'),
            method: "POST",
            dataType: "json",
            data: {
                'categories': allowCategories,
                'identification': identification
            }
        });
    };

    /**
     * Odesle informaci o zavreni cookies okna.
     */
    var sendCloseCookieConfirmData = function () {
        $.ajax({
            url: $('.js-close-cookies-settings[data-closeurl]').data('closeurl'),
            method: "POST",
            dataType: "json",
            data: 'close'
        });
    };

    /**
     * Vygeneruje nahodny identifikator
     * @returns {string}
     */
    var guid = () => {
        var s4 = () => {
            return Math.floor((1 + Math.random()) * 0x10000)
                .toString(16)
                .substring(1);
        }
        //return id of format 'aaaaaaaa'-'aaaa'-'aaaa'-'aaaa'-'aaaaaaaaaaaa'
        return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
    };

    var openPopup = function () {
        var buttonEdit = $('.js-cookies-edit');
        buttonEdit.removeClass('js-cookies-save-edit');
        buttonEdit.text(buttonEdit.data('edit'));

        $('.js-cookies-confirm-info').show();
        $('.js-cookies-settings').hide();
        $('.js-cookies-confirm').addClass("is-active");
    };

    var closePopup = function () {
        $('.js-cookies-confirm').removeClass("is-active");
        sendCloseCookieConfirmData();
    };

    var refuseAll = function (clickedElem) {
        var confirmButtons = $('.js-confirm-buttons');
        var cookieCode = confirmButtons.data('cookiecode');
        var cookieSetting = [];
        var deniedCookies = [];

        // povolene cookies
        $('.js-cookie-input.js-cookie-required').each(function () {
            cookieSetting.push($(this).attr('value'))
        });

        // zakazane cookies
        $('.js-cookie-input:not(.js-cookie-required)').each(function () {
            deniedCookies.push($(this).attr('value'))
        });
        $('.js-cookie-hidden-input').each(function () {
            deniedCookies.push($(this).attr('name'))
        });

        var cookieSettingCoded = btoa(JSON.stringify(cookieSetting));
        var expirationDays = parseInt(confirmButtons.data('refusevalidity'));
        var cookieIdentification = guid();

        saveCookieSetting(cookieSettingCoded, cookieCode, cookieIdentification, expirationDays);
        sendGtmCookieUpdate(cookieSetting, deniedCookies);
        sendCookieConfirmData(cookieSetting, cookieIdentification);
        $('.js-cookies-confirm').removeClass('is-active');

        location.reload();
    };

    var allowAll = function (clickedElem) {
        var confirmButtons = $('.js-confirm-buttons');
        var cookieCode = confirmButtons.data('cookiecode');
        var cookieSetting = [];

        // povolene cookies
        $('.js-cookie-input').each(function () {
            cookieSetting.push($(this).attr('value'))
        });
        $('.js-cookie-hidden-input').each(function () {
            cookieSetting.push($(this).attr('name'))
        });

        var cookieSettingCoded = btoa(JSON.stringify(cookieSetting));
        var cookieIdentification = guid();

        saveCookieSetting(cookieSettingCoded, cookieCode, cookieIdentification);
        sendGtmCookieUpdate(cookieSetting, []);
        sendCookieConfirmData(cookieSetting, cookieIdentification);
        location.reload();
    };

    var allowSelection = function (clickedElem) {
        var confirmButtons = $('.js-confirm-buttons');
        var cookieCode = confirmButtons.data('cookiecode');
        var cookieSetting = [];
        var deniedCookies = [];

        // povolene cookies
        $('.js-cookie-input:checked').each(function () {
            cookieSetting.push($(this).attr('value'))
        });

        // zakazane cookies
        $('.js-cookie-hidden-input').each(function () {
            deniedCookies.push($(this).attr('name'))
        });

        $('.js-cookie-input:not(:checked)').each(function () {
            deniedCookies.push($(this).attr('value'))
        });

        var cookieSettingCoded = btoa(JSON.stringify(cookieSetting));
        var cookieIdentification = guid();

        saveCookieSetting(cookieSettingCoded, cookieCode, cookieIdentification);
        sendGtmCookieUpdate(cookieSetting, deniedCookies);
        sendCookieConfirmData(cookieSetting, cookieIdentification);

        location.reload();
    };

    var showCookieList = function (clickedElem) {
        $('.js-cookies-confirm-info').hide();
        $('.js-cookies-settings').show();
        clickedElem.text(clickedElem.data('allow'));
        clickedElem.addClass('js-cookies-save-edit');
    };

    return {
        init: function () {
            var cookieCode = $('.js-confirm-buttons').data('cookiecode');
            var cookie = getCookieSettings(cookieCode);

            // automaticke otevreni popupu
            if (!cookie && !$('.js-hide-confirm-on-close').length) {
                openPopup();
            }

            // otevreni poupu pomoci triduy u odkazu
            $('.js-cookies-settings-show').on('click', function (event) {
                event.preventDefault();
                openPopup();
            });

            // otevreni nastaveni cookies
            $(".js-cookies-confirm-settings-item-opener").on("click", function () {
                var item = $(this).closest(".js-cookies-confirm-settings-item");
                item.toggleClass("is-active");
                item.find(".js-cookies-confirm-settings-item-content").slideToggle("linear");
            });

            // zavreni popupu pomoci tlacitka
            $('.js-close-cookies-settings').on('click', function () {
                closePopup();
            });

            // zavreni popupu pomoci ESC
            $(document).keyup(function (e) {
                if (e.keyCode === 27) {
                    closePopup();
                }
            });

            var body = $("body");

            // odmitnuti vsech cookies
            body.on("click", ".js-cookies-refuse", function () {
                refuseAll($(this));
            });

            // upravit nastaveni cookies - zobrazeni podrobneho nastaveni
            body.on("click", ".js-cookies-edit", function () {
                if ($(this).hasClass('js-cookies-save-edit')) {
                    // ulozeni aktualniho podrobneho nastaveni
                    allowSelection($(this));
                } else {
                    // zobrazeni podrobneho nastaveni
                    showCookieList($(this));
                }
            });

            // prijmout vsechny cookies
            body.on('click', '.js-cookies-allow', function () {
                allowAll($(this));
            });
        },
        openPopup: openPopup
    }
})();

$(document).ready(function () {
    CookiesConfirmDialog.init();
});

module.exports = CookiesConfirmDialog;