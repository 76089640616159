const GtmEvents = (function () {
    /**
     * Odesle event pridani do/odebrani z kosiku.
     *
     * @param operation
     * @param quantity
     * @param dataContainer
     */
    let sendBasketUpdateEvent = function (operation, quantity, dataContainer) {
        let productData = {
            id: dataContainer.data("id"),
            name: dataContainer.data("name"),
            price: dataContainer.data("price"),
            category: dataContainer.data("category"),
            quantity: quantity
        };

        if (dataContainer.data("variant-id")) {
            productData.variant = dataContainer.data("variant-id")
        }

        let eventData = {};
        if (operation === "addToCart") {
            eventData.add = {
                products: [productData]
            };

            dataLayer.push({
                event: "add_to_cart",
                value: productData.price,
                items: [{id: productData.id}]
            });
        } else {
            eventData.remove = {
                products: [productData]
            };
        }

        dataLayer.push({
            event: operation,
            ecommerce: eventData
        });
    };

    return {
        init: function () {
            let body = $("body");

            // kliknuti na polozku produktu ve vypisu
            body.on("click", ".js-product-link", function () {
                let link = $(this);
                let product = link.closest(".js-item");
                let productData = {
                    id: product.data("id"),
                    name: product.data("name"),
                    price: product.data("price"),
                    category: product.data("category")
                };
                if (product.data("variant-id")) {
                    productData.variant = product.data("variant-id")
                }

                dataLayer.push({
                    event: "productClick",
                    ecommerce: {
                        click: {
                            products: [productData]
                        }
                    }
                });
            });

            // vlozeni produktu do kosiku pres tlacitko Koupit
            body.on("submit", "form[name='add_to_basket']", function () {
                let form = $(this);

                let submitButton = form.find(".js-add-to-basket-submit");
                let productData = {
                    id: submitButton.data("id"),
                    name: submitButton.data("name"),
                    price: submitButton.data("price"),
                    category: submitButton.data("category"),
                    quantity: parseInt(form.find(".js-quantity").val())
                };
                if (submitButton.data("variant-id")) {
                    productData.variant = submitButton.data("variant-id")
                }

                dataLayer.push({
                    event: "addToCart",
                    ecommerce: {
                        add: {
                            products: [productData]
                        }
                    }
                });
            });
        },
        sendBasketUpdateEvent: sendBasketUpdateEvent
    }
})();

$(document).ready(function () {
    GtmEvents.init();
});

module.exports = GtmEvents;