var QueryString = require("query-string");
/* 
 *  Pokročilá filtrace ve výpisu produktů
 *  @author Borek Hanzl <borek.hanzl@blueghost.cz>
 */


var ParameterFilter = {
    // CSS selectory
    c: {
        form: "form.js-form",
        input: "form.js-form input,form.js-form select,form.js-form textarea",
        cancel_button: "form.js-form .js-cancel",
        unset_button: "form.js-form .js-unset-value",
        default_option: "option[value='']:first"
    },
    // Data a proměnné
    v: {
        initialized: false,
    },
    // interní funkce a handlery
    h: {
        inputChange: function () {
            ParameterFilter.v.container[0].dispatchEvent(new CustomEvent("ParameterFilter.change"
                , {detail: ParameterFilter.v.container.find(ParameterFilter.c.form)}));
        },
        cancelButton: function () {
            ParameterFilter.v.container.find(ParameterFilter.c.input).each(function () {
                var elem = $(this);
                if (elem.is("select")) {
                    elem.find(ParameterFilter.c.default_option).prop("selected", true);
                }
                if (elem.is("input[type=checkbox]")) {
                    elem.prop("checked", false);
                }
            });
            ParameterFilter.h.inputChange();
        },
        unsetButton: function () {
            var system_name = $(this).data("value");
            ParameterFilter.v.container.find("input[value='" + system_name + "'], select[name='" + system_name + "']").each(function () {
                var elem = $(this);
                if (elem.is("select")) {
                    elem.find(ParameterFilter.c.default_option).prop("selected", true);
                }
                if (elem.is("input[type=checkbox]")) {
                    elem.prop("checked", false);
                }
            });
            ParameterFilter.h.inputChange();
        }
    },
    /**
     * Inicializuje pokročilou filtraci nad zvoleným elementem
     * @param {jQuery} container Element obsahující všechny checkboxy hodnot parametrů
     * @returns {undefined}
     */
    init: function (container) {
        ParameterFilter.v.initialized = true;
        ParameterFilter.v.container = container;
        ParameterFilter.v.container.on("change", ParameterFilter.c.input, ParameterFilter.h.inputChange);
        ParameterFilter.v.container.on("click", ParameterFilter.c.cancel_button, ParameterFilter.h.cancelButton);
        ParameterFilter.v.container.on("click", ParameterFilter.c.unset_button, ParameterFilter.h.unsetButton);
    },
    onChange: function (callback) {
        if (!ParameterFilter.v.initialized) {
            return;
        }
        ParameterFilter.v.container[0].addEventListener("ParameterFilter.change", callback);
    },
    updateActiveFilters: function (query_object) {
        if (!ParameterFilter.v.initialized) {
            return;
        }
        var checkboxes = ParameterFilter.v.container.find(ParameterFilter.c.input).filter("[type=checkbox]");
        checkboxes.prop("checked", false);
        var param_names = Object.keys(query_object);

        for (var i = 0; i < param_names.length; i++) {
            var parameter_name = param_names[i];
            var input_name = parameter_name.concat("[]");
            if (query_object[parameter_name] && query_object[parameter_name].constructor === Array) {
                //Zaškrtnuto více hodnot
                for (var x = 0; x < query_object[parameter_name].length; x++) {
                    checkboxes.filter("[name='" + input_name + "'][value='" + query_object[parameter_name][x] + "']")
                        .prop("checked", true);
                }
            } else {
                checkboxes.filter("[name='" + input_name + "'][value='" + query_object[parameter_name] + "']")
                    .prop("checked", true);
            }
        }
    }
};

module.exports = ParameterFilter;