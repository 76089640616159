var ItemList = require("../ItemList");
var Utils = require("../Utils");
var ParameterFilter = require("./ParameterFilter");
var QueryString = require("query-string");

var ProductList = function(options) {
    ItemList.call(this, options);

    if (typeof options.filtrationContainer !== "undefined") {
        this.filtrationContainer = options.filtrationContainer;
    }

    var self = this;
    ParameterFilter.onChange(function (e) {
        self.parameterFilterChanged.call(self, e);
    });

    // stisknuto tlacitko zpet v prohlizeci
    window.onpopstate = function (e) {
        if (e.state != null && e.state.hasOwnProperty("query")) {
            // nacti polozky v seznamu
            self.loadItems(e.state["query"]);
            // nastav aktivni filtr a razeni
            self.setActiveOrder(e.state["order"]);
            self.setActiveFilter(e.state["filter"]);
            self.setActiveParameterFilter.call(self, e.state["query"]);
        }
    };
};

ProductList.prototype = Object.create(ItemList.prototype);

/**
 * Dle url změním zaškrtnutí checkboxů
 * @param {string} query query string filtrů
 * @returns {undefined}
 */
ProductList.prototype.setActiveParameterFilter = function (query) {
    var query_object = QueryString.parse(query, {arrayFormat: "bracket"});
    ParameterFilter.updateActiveFilters(query_object);
};
/**
 * Událost změny filtrů dle parametrů
 * @param {CustomEvent} e
 * @returns {undefined}
 */
ProductList.prototype.parameterFilterChanged = function (e) {
    //Aktuální filtry
    var filter_data = decodeURI(e.detail.serialize());
    var filter_object = QueryString.parse(filter_data, {arrayFormat: "bracket"});
    var query = this.getUpdatedQuery(filter_object, true);
    this.loadItems(query);
    this.pushNewBrowserHistoryState(query);
};

/**
 * Vrati aktualizovany query string s novymi hodnotami strankovani, filtrace nebo razeni.
 */
ProductList.prototype.getUpdatedQuery = function (options, clear_filters) {
    var parsedQuery = QueryString.parse(ItemList.prototype.getUpdatedQuery(options), {arrayFormat: "bracket"});
    //Bohužel parametry se ukládají pod klíčem ve formátu p[2] - není to pole pod klíčem p
    let property;
    if (clear_filters === true) {
        //Odstraním všechny staré hodnoty
        for (property in parsedQuery) {
            if (Object.prototype.hasOwnProperty.call(parsedQuery, property) && property.substring(0, 2) === "p[") {
                parsedQuery[property] = undefined;
            }
        }
    }
    //Musím tedy projít všechny property co začínají na p[ a přidat je
    for (property in options) {
        if (Object.prototype.hasOwnProperty.call(options, property) && property.substring(0, 2) === "p[") {
            parsedQuery[property] = options[property];
        }
    }
    return QueryString.stringify(parsedQuery, {encode: false, arrayFormat: "bracket"});
};
/**
 * Vlozi pocatecni state vypisu polozek do browser history.
 * Pročistí filtry parametrů a odstraní ty neplatné
 */
ProductList.prototype.initBrowserHistoryState = function () {
    var href = window.location.pathname + window.location.search,
        state = {
            query: window.location.search.substr(1), // odstrani "?" na zacatku
            order: $(".js-order.active").data("order"),
            filter: $(".js-filter.active").data("filter")
        };
    history.replaceState(state, "", href);
};

/**
 * Nacte polozky seznamu AJAXem z API.
 *
 * @param query
 * @param append Pokud je true, seznam polozek se pripoji za ten stavajici.
 */
ProductList.prototype.loadItems = function (query, append) {
    if (this.container.data("category")) {
        query = QueryString.parse(query, {arrayFormat: "bracket"});
        query.category = this.container.data("category");
        query = QueryString.stringify(query, {encode: false, arrayFormat: "bracket"});
    }

    var url = Utils.createUrl(this.container.data("load-url"), query);

    var self = this;

    this.itemLoader.load(url, append, function (response) {
        if (response.status === "success") {
            if (typeof self.filtrationContainer !== "undefined") {
                //Nahrazeni filtrace parametru
                //Musíme zachovat rozbalené skupiny
                var newContent = $(response.html.filtration);
                self.filtrationContainer.html(newContent.html());
            }
        }
    });
};

$(document).ready(function () {
    var mainContainer = $(".js-product-list");
    if (mainContainer.length === 0) {
        return;
    }
    ParameterFilter.init(mainContainer);
    new ProductList({
        "mainContainer": mainContainer,
        "listContainer": mainContainer.find(".js-product-container"),
        "filtrationContainer": mainContainer.find(".js-product-arrangement")
    });
});