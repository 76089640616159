var AddressHelper = (function () {
    var isAddressStreetNumber = function (input) {
        var name = input.attr("name");
        if (typeof name === "undefined") {
            return false;
        }
        return name.indexOf("streetNumber") !== -1;
    };

    var isAddressOrientationNumber = function (input) {
        var name = input.attr("name");
        if (typeof name === "undefined") {
            return false;
        }
        return name.indexOf("orientationNumber") !== -1;
    };

    return {
        getStreetInfoComplementaryNumber: function (input) {
            var name, target;
            if (isAddressOrientationNumber(input)) {
                name = input.attr("name").replace("orientationNumber", "streetNumber");
                target = $("[name='" + name + "']");
            } else if (isAddressStreetNumber(input)) {
                name = input.attr("name").replace("streetNumber", "orientationNumber");
                target = $("[name='" + name + "']");
            }
            return target;
        }
    }
})();

module.exports = AddressHelper;