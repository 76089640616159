/**
 * Prihlaseni do aplikace pomoci poup okna.
 *
 * @type {{init}}
 */
var LoginPopup = (function () {
    var loginPopup = $("#login-popup"); // kontejner popupu
    var orderLoginPopup = false; // flag, zda se ma vykreslit popup v ramci objednavky

    /**
     * Handle AJAXovych volani pro nacitani obou formularu v ramci popupu.
     */
    var loginAjaxLoadHandle;
    var passwordAjaxLoadHandle;

    /**
     * Nacte do popupu formular pro prihlaseni.
     *
     * @param callback
     */
    function loadLoginFormHtml(callback) {
        var url = loginPopup.data("login-url");
        if (orderLoginPopup) {
            url += "?order_popup=1";
        }

        loginAjaxLoadHandle = $.ajax({
            url: url,
            method: "GET",
            success: function (response) {
                loginPopup.find(".js-login-form").html(response.html);
                if (typeof callback !== "undefined") {
                    callback();
                }
            }
        });
    }

    /**
     * Nacte do poupu formular pro obnoveni hesla.
     *
     * @param callback
     */
    function loadPasswordFormHtml(callback) {
        var url = loginPopup.data("password-url");
        if (orderLoginPopup) {
            url += "?order_popup=1";
        }

        passwordAjaxLoadHandle = $.ajax({
            url: url,
            method: "GET",
            success: function (response) {
                loginPopup.find(".js-password-form").html(response.html);
                if (typeof callback !== "undefined") {
                    callback();
                }
            }
        });
    }

    /**
     * Odesle formular pro prihlaseni.
     *
     * @param form
     */
    function submitLoginForm(form) {
        var data = form.serialize();
        if (orderLoginPopup) {
            data += "&order_popup=1";
        }

        $.ajax({
            url: loginPopup.data("login-url"),
            method: "POST",
            data: data,
            success: function (response) {
                if (response.status === "success") {
                    window.location.reload();
                } else {
                    loginPopup.find(".js-login-form").html(response.html);
                }
            }
        });
    }

    /**
     * Odesle formular pro obnovu hesla.
     *
     * @param form
     */
    function submitPasswordForm(form) {
        var data = form.serialize();
        if (orderLoginPopup) {
            data += "&order_popup=1";
        }

        $.ajax({
            url: loginPopup.data("password-url"),
            method: "POST",
            data: data,
            success: function (response) {
                loginPopup.find(".js-password-form").html(response.html);
            }
        });
    }

    /**
     * Inicializuje popup pro prihlaseni v procesu objednavky.
     */
    function initOrderLoginPopup() {
        orderLoginPopup = true;

        loginAjaxLoadHandle.abort();
        passwordAjaxLoadHandle.abort();

        loadLoginFormHtml(function () {
            loginPopup.dialog("open");
        });
        loadPasswordFormHtml();
    }

    function initEventHandlers() {
        // zobrazeni formulare pro obnoveni hesla
        loginPopup.on("click", ".js-forgot-password", function () {
            $(".js-login-form").hide();
            $(".js-password-form").show();
            // prenacti na pozadi formular pro prihlaseni
            loadLoginFormHtml();
        });

        // zobrazeni formulare pro prihlaseni
        loginPopup.on("click", ".js-show-login", function () {
            $(".js-password-form").hide();
            $(".js-login-form").show();
            // prenacti na pozadi formulare pro obnoveni hesla
            loadPasswordFormHtml();
        });

        loginPopup.on("submit", ".js-login-form form", function (e) {
            e.preventDefault();
            submitLoginForm($(this));
        });

        loginPopup.on("submit", ".js-password-form form", function (e) {
            e.preventDefault();
            submitPasswordForm($(this));
        });

        loginPopup.on("click", ".js-popup-close", function () {
            loginPopup.dialog("close");
        });

        loginPopup.on("dialogclose", function() {
            // pri zavreni popupu v objednavce se tento sepcialni poup znovu
            // jiz zobrazit nema, proto se nastavi orderLoginPopup na false
            // a prenacte se obsah skryteho popupu
            if (orderLoginPopup === true) {
                orderLoginPopup = false;
                loadLoginFormHtml();
                loadPasswordFormHtml();
            }
        });

        $(".js-login").click(function () {
            loginPopup.dialog("open");
        });
    }

    return {
        init: function () {
            if (loginPopup.length === 0) {
                return;
            }

            loginPopup.dialog({
                modal: true,
                draggable: false,
                autoOpen: false,
                resizable: false
            });

            initEventHandlers();
            loadLoginFormHtml();
            loadPasswordFormHtml();
        },
        showOrderLoginPopup: function () {
            if (loginPopup.length === 0 || loginPopup.data("order-popup") === 0) {
                return;
            }

            initOrderLoginPopup();
        }
    }
})();

$(document).ready(function () {
    LoginPopup.init();
});

module.exports = LoginPopup;