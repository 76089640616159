var Utils = (function () {
    return {
        /**
         * Vytvori URL s query stringem.
         */
        createUrl: function (baseUrl, query) {
            if (query !== "") {
                baseUrl += "?" + query;
            }
            return baseUrl;
        },

        /**
         * Scrollne stranku k pozadovanemu offsetu shora.
         *
         * @param offset
         * @param duration
         */
        scrollBody: function (offset, duration) {
            let offsetCorrection = 0;
            let pageHeader = $(".js-page-header");
            let pageHeaderPositioning = pageHeader.css("position");
            if(pageHeaderPositioning == "fixed" || pageHeaderPositioning == "sticky") {
                offsetCorrection = pageHeader.outerHeight(true);
            }

            duration = duration || 0;

            $("html, body").animate({
                scrollTop: offset - offsetCorrection
            }, duration);
        },
    }
})();

module.exports = Utils;