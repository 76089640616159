/**
 * Univarzalni input pro pocet kusu.
 *
 * @type {{init: QuantityBox.init}}
 */
var QuantityBox = (function () {
    return {
        init: function () {
            var body = $("body");

            // kliknuti na "+"
            body.on("click", ".js-quantity-box .js-add", function () {
                var container = $(this).closest(".js-quantity-box");
                var quantityInput = container.find(".js-quantity");

                var quantity = parseInt(quantityInput.val());
                quantity += 1;
                quantityInput.val(quantity);

                if (container.hasClass("js-basket-quantity-box")) {
                    // update udaju v kosiku
                    quantityInput.trigger("change");
                }
            });

            // Kontrola zadane hodnoty do inputu pro pocet kusu
            body.on("change", ".js-quantity-box .js-quantity", function () {
                var quantity = $(this).val();
                if (!Number.isInteger(parseInt(quantity))) {
                    quantity = 1;
                }
                if (parseInt(quantity) < 1) {
                    quantity = 1;
                }
                $(this).val(quantity);
            });

            // kliknuti na "-"
            body.on("click", ".js-quantity-box .js-remove", function () {
                var container = $(this).closest(".js-quantity-box");
                var quantityInput = container.find(".js-quantity");

                var quantity = parseInt(quantityInput.val());
                quantity -= 1;
                if (quantity < 1) {
                    if (container.hasClass("js-basket-quantity-box")) {
                        // v kosiku je mozne jit pod 1 kus
                        // 0 == odebrani z kosiku
                        quantity = 0;
                    } else {
                        quantity = 1;
                    }
                }
                quantityInput.val(quantity);

                if (container.hasClass("js-basket-quantity-box")) {
                    // update udaju v kosiku
                    quantityInput.trigger("change");
                }
            });
        }
    }
})();

$(document).ready(function () {
    QuantityBox.init();
});