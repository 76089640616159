/**
 * Stara se aktualizace nastaveni distribuce pro objednavku.
 */
var DeliveryUpdater = (function () {
    var basketDeliveryContainer = $(".js-basket-delivery");

    /**
     * Zobrazi nebo skryje motivacni zpravu pro doruceni.
     * @param message
     */
    var updateDeliveryMessage = function (message) {
        var messageContainer = $(".js-delivery-message");
        if (typeof message !== "undefined") {
            messageContainer.text(message).show();
        } else {
            messageContainer.text("").hide();
        }
    };

    /**
     * Zobrazi polotku dopravy v kosiku.
     * @param name
     * @param price
     */
    var showBasketDelivery = function (name, price) {
        basketDeliveryContainer.find(".js-basket-delivery-name").text(name);
        basketDeliveryContainer.find(".js-basket-delivery-price").text(price);
        basketDeliveryContainer.show();
    };

    /**
     * Skryje polozku dopravy v kosiku.
     */
    var hideBasketDelivery = function () {
        basketDeliveryContainer.find(".js-basket-delivery-name").text("");
        basketDeliveryContainer.find(".js-basket-delivery-price").text("");
        basketDeliveryContainer.hide();
    };

    /**
     * Zobrazi nebo skryje polozku pro dopravu v ramci souhrnu kosiku.
     * @param delivery
     */
    var updateBasketDelivery = function (delivery) {
        if (typeof delivery.price !== "undefined") {
            showBasketDelivery(delivery.name, delivery.price);
        } else {
            hideBasketDelivery();
        }
    };

    /**
     * Aktualizuje vybranou zemi pro distribuci.
     * @param countryId
     */
    var updateSelectedCountry = function (countryId) {
        if (typeof countryId !== "undefined") {
            $(".js-country").val(countryId).trigger("material.change");
        }
    };

    /**
     * Aktualizuje vybrany zpusob dopravy.
     * @param shippingId
     */
    var updateSelectedShipping = function (shippingId) {
        $(".js-shipping").prop("checked", false);
        if (typeof shippingId !== "undefined") {
            var item = $(".js-shipping[value=" + shippingId + "]");
            item.prop("checked", true);

            var serviceItem = item.closest(".js-service-item");
            serviceItem.find(".js-description").show();

            // zobrazit vyber vydejniho mista
            if (serviceItem.data("system-name")) {
                serviceItem.find(".js-delivery-pickup-widget[data-type='" + serviceItem.data("system-name") + "']").show();
            }
        }
    };

    /**
     * Aktualizuje vybrany zpusob platby.
     * @param paymentId
     */
    var updateSelectedPayment = function (paymentId) {
        $(".js-payment").prop("checked", false);
        if (typeof paymentId !== "undefined") {
            var item = $(".js-payment[value=" + paymentId + "]");
            item.prop("checked", true);
            item.closest(".js-service-item").find(".js-description").show();
        }
    };

    /**
     * Aktualizuje blok celkove ceny dopravy.
     * @param totalPrice
     */
    var updateDeliveryPrice = function (totalPrice) {
        var container = $(".js-delivery-price");
        if (typeof totalPrice !== "undefined") {
            var text = "";
            if (totalPrice.match(/^\d+/gm)) {
                text += "+";
            }
            text = text + " " + totalPrice;
            container.find(".js-delivery-total-price").text(text);
            container.show();
        } else {
            container.find(".js-delivery-total-price").text("");
            container.hide();
        }
    };

    return {
        update: function (delivery) {
            updateBasketDelivery(delivery);
            updateDeliveryMessage(delivery.message);
            updateDeliveryPrice(delivery.price);
            updateSelectedCountry(delivery.country);
            updateSelectedShipping(delivery.shipping);
            updateSelectedPayment(delivery.payment);
        }
    };
})();

module.exports = DeliveryUpdater;