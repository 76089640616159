var OrderSuccess = (function () {
    return {
        init: function () {
            var container = $(".js-order-success");
            if (container.length === 0) {
                return;
            }

            var paymentButton = container.find(".js-button-pay");
            if (paymentButton.length) {
                var redirectTo = paymentButton.prop("href");

                setTimeout(function () {
                    window.location.href = redirectTo;
                }, 3000);
            }
        }
    };
})();

$(document).ready(function () {
    OrderSuccess.init();
});