/**
 * Seznam objednavek uzivatele.
 *
 * @type {ItemList}
 */

var ItemList = require("../ItemList");

var OrderList = function(options) {
    ItemList.call(this, options);
};

OrderList.prototype = Object.create(ItemList.prototype);

$(document).ready(function () {
    var mainContainer = $(".js-order-list");
    if (mainContainer.length === 0) {
        return;
    }

    new OrderList({
        "mainContainer": mainContainer,
        "listContainer": mainContainer.find(".js-orders-container")
    });
});