var Utils = require("./Utils");

/* mobilní skupina elementů (většinou obsahuje menu a přepínač jazyků) */

var GroupMobile = (function () {

    let opener = $(".js-group-mobile-opener");
    let group = $(".js-group-mobile");
    let groupContent = $(".js-group-mobile-content");

    // otevírání/zavírání skupiny
    let toggle = function () {
        $("body").toggleClass("is-group-mobile-active");
        opener.toggleClass("is-active");
        group.toggleClass("is-active");
        set();
        groupContent.slideToggle(300, "linear");
    };

    // nastavuje výšku a umístění
    let set = function () {
        let windowHeight = $(window).height();
        let windowScrolled = $(window).scrollTop();
        let headerHeight = $(".js-page-header").outerHeight();
        let infoBarHeight = 0;
        if($(".js-info-bar").length) {
            infoBarHeight = $(".js-info-bar").outerHeight();
        }
        group.css({
            "height": windowHeight - headerHeight - infoBarHeight + windowScrolled,
            "top": headerHeight + infoBarHeight - windowScrolled
        })
    };

    // otevírání/zavírání jednotlivých úrovní
    let toggleSubmenu = function () {
        $(".js-menu-children-toggle").on("click", function () {
            $(this).siblings(".js-menu-level").slideToggle(300, "linear");
            $(this).closest(".js-menu-item").toggleClass("is-active");
        });
    };

    let init = function () {
        opener.on("click", function () {
            toggle();
        });
        toggleSubmenu();
        $(window).resize(function () {
            set();
        })
    };

    return {
        init: init,
        toggle: toggle,
        toggleSubmenu: toggleSubmenu,
        set: set
    }
})()

$(document).ready(function () {
    GroupMobile.init();
});

module.exports = GroupMobile;