/* carousels */
const Carousels = (function () {

    // carousel v upsell popupu
    let initUpsellPopupCarousel = function () {
        let container = $(".js-upsell-popup-products");
        if (container.length === 0) {
            return
        }
        container.slick({
            mobileFirst: true,
            dots: false,
            appendDots: "",
            arrows: true,
            appendArrows: ".js-upsell-popup-arrows",
            speed: 1200,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: false,
            autoplaySpeed: 3000,
            infinite: false,
            fade: false,
            responsive: [
                {
                    breakpoint: 639,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                    }
                }
            ]
        });

        container.find(".js-item").each(function () {
            $(this).removeAttr("style");
        })
    };

    return {
        initUpsellPopupCarousel: initUpsellPopupCarousel
    }

})();

module.exports = Carousels;