var BasketUpdater = require("./BasketUpdater");
var DeliveryUpdater = require("./DeliveryUpdater");

var OrderUpdater = (function () {
    /**
     * Aktualizuje uzivatelske inputy (kontakt, adresy, firma, atd.)
     * @param data
     */
    var updateInputs = function (data) {
        var orderForm = $("#order-form");

        $.each(data, function (key, value) {
            var input = orderForm.find(":input[name='" + key + "']");
            if (input.length === 0 || input.hasClass("is-processing") || input.hasClass("is-invalid")) {
                // input neexistuje, nebo je nezadouci ho prepisovat
                return;
            }

            // s checkboxy se musi pracovat jinak nez s ostatnimi inputy
            if (input.attr("type") === "checkbox") {
                input.prop("checked", value);
            } else {
                input.val(value);
            }

            input.trigger("material.change");
        });
    };

    /**
     * Aktualizuje celkovou cenu objednavky.
     * @param totalPrice
     */
    var updateTotalPrice = function (totalPrice) {
        $(".js-order-total-price").text(totalPrice);
    };

    var updateFreeGifts = function (freeGiftsData) {
        if (!freeGiftsData) {
            return;
        }

        let container = $(".js-free-gift-message");
        const messageHtml = freeGiftsData.messageHtml || null;
        const giftsHtml = freeGiftsData.giftsHtml || null;

        if (!messageHtml) {
            container.html("").hide();
        } else {
            container.html(messageHtml).show();
        }

        container = $(".js-free-gifts");
        if (!giftsHtml) {
            container.html("");
        } else {
            container.html(giftsHtml);
        }

        updateFreeGiftBasketItem(freeGiftsData.basketItemHtml)
    };

    /**
     * Aktualizuje polozku darku zdarma v kosiku.
     * @param basketItemHtml
     */
    var updateFreeGiftBasketItem = function (basketItemHtml) {
        const container = $(".js-basket-free-gift");

        if (typeof basketItemHtml === "undefined") {
            container.html("").hide();
        } else {
            container.html(basketItemHtml).show();
        }
    };

    /**
     * Aktualizuje slevu v objednavce.
     * @param discount
     */
    var updateDiscount = function (discount) {
        var basketItemContainer = $(".js-order-discount");
        var codeInputContainer = $(".js-discount-container");

        if (typeof discount === "undefined") {
            basketItemContainer.empty();
            codeInputContainer.show();
            codeInputContainer.find(".js-discount-code-container").hide(150, "linear");
            codeInputContainer.find(".js-discount-checkbox").prop("checked", false);
            codeInputContainer.find(".js-discount-code").val("");
        } else {
            basketItemContainer.html(discount.html);
        }
    };

    return {
        update: function (orderData) {
            // update obsahu kosiku
            BasketUpdater.update(orderData.basket);
            // update kombinace zeme, doprava, platba
            DeliveryUpdater.update(orderData.delivery);
            // update slevy
            updateDiscount(orderData.discount);
            // update celkove ceny objednavky
            updateTotalPrice(orderData.totalPrice);
            // update textovych inputu a checkboxu pro uzivatelske udaje
            updateInputs(orderData.orderInfo);
            // update sekce s darky zdarma
            updateFreeGifts(orderData.freeGift);
        },
        updateFreeGifts: updateFreeGifts
    };
})();

module.exports = OrderUpdater;