require("./Basket");
require("./Delivery");
require("./FreeGifts");
require("./DeliveryPickupPopup");
var OrderUpdater = require("./view/OrderUpdater");
var OrderValidator = require("./view/OrderValidator");
var AddressHelper = require("../address/AddressHelper");
var LoginPopup = require("../LoginPopup");
var Utils = require("../Utils");
var Loader = require("../Loader");

var Order = (function () {
    var orderContainer;
    // jquery element formulare objednavky
    var orderForm;

    /**
     * Vytvori data pro editaci objednavky pomoci upravy jednoho inputu.
     * @param input
     * @returns {FormData}
     */
    var createSingleInputFormData = function (input) {
        var
            formData = new FormData(),
            name = input.attr("name"),
            type = input.attr("type"),
            value = null;

        // pokud se jedna o checkbox a neni zaskrtnuty, tak pridej -1,
        // jinak pridej value inputu
        if (type === "checkbox" && !input.is(":checked")) {
            value = "-1";
        } else {
            value = input.val();
        }

        formData.append(name, value);
        formData.append("_token", $("[name=_token]").val());

        // pripojeni cisla popisneho nebo orientacniho, obe musi byt odesilana vzdy spolecne
        var complementary = AddressHelper.getStreetInfoComplementaryNumber(input);
        if (typeof complementary !== "undefined") {
            formData.append(complementary.attr("name"), complementary.val());
        }

        return formData;
    };

    /**
     * Odesle na server pozadavek na aktualizaci objednavky pomoci jednoho inputu.
     * @param input
     */
    var sendSingleInputOrderUpdate = function (input) {
        var data = createSingleInputFormData(input);

        $.ajax({
            url: orderForm.data("edit-url"),
            method: "POST",
            data: data,
            cache: false,
            contentType: false,
            processData: false,
            beforeSend: function () {
                OrderValidator.startInputProgress(input);
            },
            complete: function () {
                OrderValidator.endInputProgress(input);
            },
            success: function (response) {
                if (response.status === "success") {
                    OrderValidator.handleInputSuccess(input);
                    OrderUpdater.update(response.data);
                } else {
                    OrderValidator.handleInputError(input, response.errors);
                }
            },
            error: function () {
                // TODO: nekam zobrazit obecnou chybovou hlasku?
                OrderValidator.endInputProgress(input);
            }
        });
    };

    /**
     * Odesle na server pozadavek na aktualizaci cele objednavky.
     */
    var sendOrderUpdate = function () {
        var data = new FormData(orderForm[0]);
        $.ajax({
            url: orderForm.data("edit-url"),
            method: "POST",
            data: data,
            cache: false,
            contentType: false,
            processData: false,
            beforeSend: function () {
                OrderValidator.startFormProgress();
            },
            success: function (response) {
                if (response.status === "success") {
                    orderForm.submit();
                } else {
                    OrderValidator.showAllErrors(response.errors);
                    // scroll k prvnimu nevalidnimu inputu
                    Utils.scrollBody($(":input.is-invalid").first().offset().top - 75, 500);
                    OrderValidator.endFormProgress();
                }
            },
            error: function () {
                // TODO: nekam zobrazit obecnou chybovou hlasku?
                OrderValidator.endFormProgress();
            }
        });
    };

    return {
        init: function () {
            orderContainer = $(".js-order-container");
            if (orderContainer.length === 0) {
                return;
            }

            orderForm = $("#order-form");

            var inputs = orderForm.find(":input").not(".js-static");
            inputs.each(function () {
                if ($(this).val() !== "" && !$(this).prop("disabled")) {
                    $(this).addClass("is-valid");
                }
            });

            inputs.on("input", function () {
                $(this).addClass("is-processing");
            });

            // zmena hodnoty inputu v objednavce
            inputs.on("change", function () {
                if ($(this).hasClass("js-collapse-trigger") && $(this).is(":checked")) {
                    // pro zabalovaci sekce chceme odesilat request pouze pri odskrtnuti
                    return;
                }
                sendSingleInputOrderUpdate($(this));
            });

            // zabaleni a rozbaleni sekce s form prvky
            $(".js-collapse-trigger").on("change", function () {
                var collapseContent =
                    $(".js-collapse-content[data-id=" + $(this).data("id") + "]");

                if (collapseContent.length === 0) {
                    return;
                }

                var invoicingCountry = orderForm.find("[name='invoicingAddress[country]']");
                if ($(this).is(":checked")) {
                    collapseContent.slideDown();

                    if ($(this).attr("name") === "addShippingAddress") {
                        // pokud byla otevrena dodaci adresa, uprav nastaveni zeme fakturacni adresy
                        // nastav dodaci zemi na stejnou zemi jako distribuce a odemkni moznosti upravovat
                        // fakturacni zemi
                        var shippingCountry = collapseContent.find(":input[name*=country]");
                        shippingCountry.val($(".js-country").val()).trigger("material.change");
                        sendSingleInputOrderUpdate(shippingCountry);
                        invoicingCountry.prop("disabled", false);
                    }
                } else {
                    collapseContent.slideUp(400, function () {
                        // po zabaleni odstran errory a zvyrazneni
                        collapseContent.find(":input").removeClass("is-valid is-invalid");
                        collapseContent.find(".js-form-errors").html("");
                    });

                    if ($(this).attr("name") === "addShippingAddress") {
                        // pokud byla zavrena dodaci adresa, uprav nastaveni zeme fakturacni adresy
                        // musi byt shodna se zemi distribuce a disabled
                        invoicingCountry.val($(".js-country").val()).prop("disabled", true);
                        sendSingleInputOrderUpdate(invoicingCountry);
                    }
                }
            });

            // odeslani formulare objednavky
            $(".js-order-submit").on("click", function (e) {
                e.preventDefault();
                // pred odeslanim formulare se provede jeste jeden update + validace
                sendOrderUpdate();
            });

            // zobrazi popup formular pro prihlaseni
            LoginPopup.showOrderLoginPopup();

            // Tlacitko "zpet k nakupu"
            $(".js-order-back").click(function () {
                 window.history.back();
            });

            // rozbaleni/zabaleni formulare pro priadni slevy
            $(".js-discount-checkbox").on("change", function () {
                $(".js-discount-code-container").slideToggle(150, "linear");
            });

            // overeni kodu slevy a pridani do kosiku
            $(".js-discount-code-submit").on("click", function (e) {
                e.preventDefault();
                var container = $(this).closest(".js-discount-container");
                var code = container.find(".js-discount-code").val();
                if (!code) {
                    return;
                }

                var data = new FormData();
                data.append("code", code);

                $.ajax({
                    url: container.data("verify-url"),
                    method: "POST",
                    data: data,
                    cache: false,
                    contentType: false,
                    processData: false,
                    beforeSend: function() {
                        Loader.show();
                    },
                    success: function (response) {
                        if (response.status === "success") {
                            OrderUpdater.update(response.data);
                            container.find(".js-form-errors").empty();
                            container.find(".js-discount-code").removeClass("is-invalid");
                            container.hide();
                        } else {
                            container.find(".js-form-errors").empty()
                                .append($("<div>").addClass("form-item__error").text(response.message));
                            container.find(".js-discount-code").addClass("is-invalid");
                        }
                    },
                    error: function () {
                        container.find(".js-form-errors").empty()
                            .append($("<div>").addClass("form-item__error").text(container.data("use-error-msg")));
                        container.find(".js-discount-code").addClass("is-invalid");
                    },
                    complete: function() {
                        Loader.hide();
                    }
                });
            });

            orderContainer.on("click", ".js-remove-discount-code", function () {
                var container = orderContainer.find(".js-discount-container");

                $.ajax({
                    url: container.data("remove-url"),
                    method: "POST",
                    beforeSend: function() {
                        Loader.show();
                    },
                    success: function (response) {
                        if (response.status === "success") {
                            OrderUpdater.update(response.data);
                            container.find(".js-discount-code-container").hide(150, "linear");
                            container.find(".js-discount-checkbox").prop("checked", false);
                            container.find(".js-discount-code").val("");
                            container.show();
                        } else {
                            orderContainer.find(".js-dynamic-messages").empty()
                                .append($("<article>").addClass("message message--error").text(response.message));
                        }
                    },
                    error: function () {
                        orderContainer.find(".js-dynamic-messages").empty()
                            .append($("<article>").addClass("message message--error").text(container.data("remove-error-msg")));
                    },
                    complete: function() {
                        Loader.hide();
                    }
                });
            });
        }
    };
})();

$(document).ready(function () {
    Order.init();
});