require("../../../node_modules/mark.js/dist/jquery.mark.min");

var SearchBar = {
    /**
     * Flag, zda je v procesu ajax pozadavek.
     */
    ajaxInProgress: null,

    /**
     * Timeout handle pro logiku odlozeneho odeslani pozadavku pro naseptavani.
     */
    debounceSearchInput: null,

    /**
     * Schova box s naseptavanim, pokud je kliknuto mimo tento box nebo input pro
     * zadavani textu.
     *
     * @param e
     */
    toggleSuggestions: function (e) {
        var clickedElement = $(e.target);
        if (
            !clickedElement.is(this.input)
            && clickedElement.closest(".js-searchbox-suggestion-list").length === 0
        ) {
            this.suggestionContainer.hide();
        } else {
            this.suggestionContainer.show();
        }
    },

    searchSuccess: function (response, query) {
        if (response.status !== "success") {
            return;
        }

        this.suggestionContainer
            .html(response.html)
            .show();

        // oznaceni query
        this.suggestionContainer.mark(query, {
            "exclude": [".js-mark-exclude"]
        });
    },

    searchInput: function () {
        var self = this;

        if (self.input.val().length < 3) {
            // odesilaji se pouze termy s delkou vetsi nez 2
            return;
        }

        if (self.ajaxInProgress !== null) {
            self.ajaxInProgress.abort();
        }

        var query = self.input.val();
        self.ajaxInProgress = $.ajax({
            method: "GET",
            url: self.input.data("url"),
            data: {q: query},
            beforeSend: function () {
                $('.js-searchbox-handle-entry').addClass('is-searching');
            },
            success: function (response) {
                self.searchSuccess(response, query);
            },
            complete: function () {
                self.ajaxInProgress = null;
                $('.js-searchbox-handle-entry').removeClass('is-searching');
            }
        });
    },

    init: function () {
        this.container = $(".js-searchbox");
        if (this.container.length === 0) {
            return;
        }

        this.toggle = $(".js-searchbox-opener");
        this.input = $(".js-searchbox-handle-entry");
        this.form = this.input.closest("form");
        this.suggestionContainer = $(".js-searchbox-suggestion-list");
        this.submitButton = this.container.find(".js-searchbox-handle-button");

        var self = this;
        this.form.on("submit", function () {
            // nechceme vyhledávat protože se odesílá formulář
            if (self.debounceSearchInput !== null) {
                clearTimeout(self.debounceSearchInput);
                self.debounceSearchInput = null;
            }
        });
        // Vyhledávat začnu až půl sekundy po zadání posledního znaku
        this.input.on("input", function () {
            if (self.debounceSearchInput !== null) {
                clearTimeout(self.debounceSearchInput);
                self.debounceSearchInput = null;
            }
            self.debounceSearchInput = setTimeout(function () {
                self.debounceSearchInput = null;
                self.searchInput();
            }, 500);
        });

        // schovani/zobrazeni sekce s naseptavanim
        $(document).click(function (e) {
            self.toggleSuggestions(e);
        });

        // zobrazeni/schovani input pro vyhledavani
        this.toggle.click(function () {
            self.container.slideToggle();
            self.input.focus();
        });

        // zavreni vyhledavani na ESC
        $(document).keyup(function (e) {
            if (e.keyCode === 27) {
                self.container.slideUp();
            }
        });

        // odeslani formulare
        this.submitButton.click(function (e) {
            e.preventDefault();

            if (self.input.val().length === 0) {
                // pouze, pokud je neco vyplneno
                return;
            }

            self.form.submit();
        });
    }
};

$(document).ready(function () {
    SearchBar.init();
});