/**
 * Flash message pro ruzne notifikace uzivateli.
 *
 * @param options
 * @constructor
 */
var FlashMessage = function (options) {
    this.container = $(".js-flash-messages");
    if (this.container.length === 0) {
        return;
    }

    var status = options.status;
    var text = options.text;
    var timeout = options.timeout || 5000;

    this.addMessage(status, text, timeout);
};

FlashMessage.prototype.emptyContainer = function () {
    this.container.empty();
};

FlashMessage.prototype.addMessage = function (status, text, timeout) {
    this.emptyContainer();

    var message = $("<article>")
        .addClass("message")
        .addClass(status)
        .text(text);

    this.container.append(message);

    setTimeout(function () {
        message.remove();
    }, timeout);
};

module.exports = FlashMessage;