var ItemList = require("../ItemList");
var Utils = require("../Utils");

var NewsList = function(options) {
    ItemList.call(this, options);
};

NewsList.prototype = Object.create(ItemList.prototype);

/**
 * Nacte polozky seznamu AJAXem z API.
 *
 * @param query
 * @param append Pokud je true, seznam polozek se pripoji za ten stavajici.
 */
NewsList.prototype.loadItems = function (query, append) {
    if (this.container.data("category")) {
        // pokud jsi na strance kategorie, vloz jeji slug do dotazu
        var categoryQuery = "category=" + this.container.data("category");
        if (query) {
            query += "&" + categoryQuery
        } else {
            query = categoryQuery;
        }
    }

    var url = Utils.createUrl(this.container.data("load-url"), query);

    this.itemLoader.load(url, append);
};

$(document).ready(function () {
    var mainContainer = $(".js-news-list");
    if (!mainContainer.length) {
        return;
    }

    new NewsList({
        "mainContainer": mainContainer,
        "listContainer": mainContainer.find(".js-news-container")
    });
});