const ItemList = require("../ItemList");

const EventList = function(options) {
    ItemList.call(this, options);
};

EventList.prototype = Object.create(ItemList.prototype);

$(document).ready(function () {
    let container = $(".js-event-list");
    if (!container.length) {
        return;
    }

    new EventList({
        "mainContainer": container,
        "listContainer": container.find(".js-event-container")
    });
});