/**
 * Stara se oaktualizace produktu v kosiku.
 */
var BasketUpdater = (function () {
    /**
     * Odstrani z vypisu produkty, ktere byly z kosiku odstraneny.
     * @param products
     */
    var deleteRemovedProducts = function (products) {
        var productElements = $(".js-basket-item");
        $.each(productElements, function () {
            var productElement = $(this);

            var remove = true;
            $.each(products, function () {
                if (this.slug === productElement.data("slug")) {
                    if (this.variantId) {
                        // pokud je to varianta, musi se shodovat i ID varianty
                        if (this.variantId === productElement.data("variant-id")) {
                            remove = false;
                            return false;
                        }
                    } else if(!productElement.data("variant-id")) {
                        // pokud to neni varianta, tak produkt, ktery nebude smazan nesmi mit nastaveny variant_id
                        // osetruje situace, kdy je v kosiku jak variantni produktu, tak jeho zakladni verze
                        remove = false;
                        return false;
                    }
                }
            });

            if (remove) {
                productElement.remove();
            }
        });
    };

    /**
     * Aktualizuje polozky produktu v kosiku dle aktualniho obsahu.
     * @param products
     */
    var updateProducts = function (products) {
        if (products.length === 0) {
            return;
        }

        deleteRemovedProducts(products);

        // TODO: udelat pres prototype
        $.each(products, function () {
            var selector = ".js-basket-item[data-slug=" + this.slug + "]";
            if (this.variantId) {
                selector += "[data-variant-id=" + this.variantId + "]";
            }
            var product = $(selector);

            // update mnozstvi a ceny
            product.find(".js-quantity").val(this.quantity);
            product.find(".js-total-product-price")
                .text(this.totalPrice);
            product.find(".js-unit-price")
                .text(this.price);
        });
    };

    /**
     * Aktualizuje celkovou cenu v nahledu kosiku.
     * @param totalPrice
     */
    var updateBasketPreview = function (totalPrice) {
        $(".js-basket-preview").find(".js-total-price").text(totalPrice);
    };

    return {
        /**
         * Aktualizuje vypis v kosiku.
         * @param basket
         */
        update: function (basket) {
            if (basket.length === 0) {
                return;
            }

            updateProducts(basket.products);
            updateBasketPreview(basket.totalPrice);
        },
        updatePreview: updateBasketPreview
    };
})();

module.exports = BasketUpdater;